app.boot(() => {
  window.addEventListener( config('ua.event.dom.LOADED'), ( event ) => {
    if( config('runMode') != 'production' ){
      console.log('dom.loaded');
    }
  });

  window.addEventListener( config('ua.event.LOADED'), ( event ) => {
    // Object.keys(process.env).forEach(( key ) => {
    //   let keys = key.split('__');
    //   let last = keys.pop();

    //   config( keys.join('.').toLowerCase() +'.'+ last, process.env[key] );
    // });

    //app.booted();

    if( config('runMode') != 'production' ){
      console.log('loaded');
    }
  });

  window.addEventListener( config('ua.event.MOUNTED'), ( event ) => {
    if( config('runMode') != 'production' ){
      console.log('mounted');
      dump();
      console.log(app);
    }
  });
});