import { Controller } from '../foundation/controller';
import { Request } from '../foundation/request';

// const {google} = require('googleapis');

import Vue from 'vue';

export class Shop extends Controller
{
  constructor( element )
  {
    super(element);
  }

  boot()
  {
    const _ = this;

    this.shop = new Vue({
      el: '#shop',

      // header: null,
      // content: null,

      data()
      {
        return {
          title: 'All Items',
          products: [],
          childs: [],
          // header: null,
          is_booted: false,
          is_loading: false
        }
      },

      mounted: function()
      {
        //this.$header = this.$el.querySelector('.app--header');
        //this.$content = this.$el.querySelector('.app--content');

        this.load({
          mode: '',
          scope: 'products',
          limit: 100
        });
      },

      methods: {
        load: function( parameters )
        {
          this.is_loading = true;

          const mode = parameters.mode;
          delete parameters.mode;

          const name = parameters.name;
          delete parameters.name;

          Request.get( parameters, ( response ) => {
            this.products = _.cleanProductData( response.data.products.filter(( item ) => {
              return item.display_state == 'showing';
            }));

            this.is_loading = false;

            if(!this.is_booted){
              this.is_booted = true;

              _.booted();
            }else{
              if( app.element.classList.contains('nav--is-active')){
                app.element.classList.remove('nav--is-active');
              }

              let title = name;
              if( mode == 'search' ){
                title = 'Search "'+ title +'"';
              }

              title += ' ('+ this.products.length +')';

              app.update({
                title: title
              });

              window.scrollTo({
                top: 0, //document.getElementById('products').getBoundingClientRect().top + window.pageYOffset - 32,
                behavior: 'smooth'
              });
            }
          });
        },
        isBooted: function()
        {
          return this.is_booted;
        },
        update: function( mode, data )
        {
          switch(mode){
            case 'search':
              this.load({
                mode: mode,
                scope: 'products',
                limit: 100,
                name: data
              });
              break;
            case 'category':
              let parameters = {
                mode: mode,
                scope: 'products',
                limit: 100,
                name: data.name,
                category_id_big: data.id_big
              };

              if(data.id_small){
                parameters.category_id_small = data.id_small;
              }//else{
                //this.title = data.name;
                this.childs = data.children;
              //}

              this.load(parameters);
              break;
            case 'group':
              //this.title = data.name;
              this.childs = [];

              this.load({
                mode: mode,
                scope: 'products',
                limit: 50,
                name: data.name,
                group_ids: data.id
              });
              break;
          }
        },
        clickChildCategory: function( index )
        {
          this.childs = _.categories.current.children;

          this.update( 'category', this.childs[index] );
        }
      }
    });

    this.categories = new Vue({
      el: '#categories',

      data()
      {
        return {
          storage: [],
          current: null,
          is_booted: false
        }
      },

      mounted: function()
      {
        Request.get(
          {
            scope: 'categories'
          },
          ( response ) => {
            this.storage = _.cleanCategoryData( response.data.categories.filter(( item ) => {
              return item.display_state == 'showing' && item.id_big > 0;
            }));

            this.is_booted = true;
            _.booted();
          }
        );
      },

      methods: {
        isBooted: function()
        {
          return this.is_booted;
        },
        clickParent: function( index )
        {
          this.current = this.storage[index];

          _.shop.update( 'category', this.current );
        }
      }
    });

    this.groups = new Vue({
      el: '#groups',

      data()
      {
        return {
          storage: [],
          is_booted: false
        }
      },

      mounted: function()
      {
        Request.get(
          {
            scope: 'groups'
          },
          ( response ) => {
            this.storage = _.cleanGroupData( response.data.groups.filter(( item ) => {
              return item.display_state == 'showing';
            }));

            this.is_booted = true;

            _.booted();
          }
        );
      },

      methods: {
        isBooted: function()
        {
          return this.is_booted;
        },
        click: function( index )
        {
          _.shop.update( 'group', this.storage[index] );
        }
      }
    });
  }

  booted()
  {
    if(  this.shop.isBooted()
      && this.categories.isBooted()
      && this.groups.isBooted()
    ){
      this.is_booted = true;

      this.renderGlobalNav();
      this.registerGlobalNavToggleEvent();

      this.buildSearchForm();
      this.registerGuideEvent();
      //this.registerGuideTableToggleEvent();

      app.booted();
    }
  }

  update( scope, id )
  {

  }

  registerGuideEvent()
  {
    document.querySelectorAll('.guide--item-explanation').toArray().forEach(( guide ) => {
      guide.querySelectorAll('dt a').toArray().forEach(( anchor ) => {
        anchor.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
          let node = anchor.parentNode.nextElementSibling;

          node.classList.toggle('is--active');
        });
      });
    });
  }

  registerGuideTableToggleEvent()
  {
    document.querySelectorAll('.guide--item-explanation').toArray().forEach(( guide ) => {
      guide.querySelectorAll('dd button').toArray().forEach(( button ) => {
        button.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
          let node = button.closest('dd');

          node.classList.toggle('is--active');
        });
      });
    });
  }

  buildSearchForm()
  {
    this.search = {
      keyword: this.element.querySelector('.shop--content-tool--search input'),
      action: this.element.querySelector('.shop--content-tool--search button')
    };

    this.search.action.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
      event.preventDefault();

      if( this.search.keyword.value == '' ){
        this.search.keyword.focus();
      }else{
        this.shop.update( 'search', this.search.keyword.value );
        this.search.keyword.value = '';
      }
    });
  }

  renderGlobalNav()
  {
    const _ = this;

    this.globalNav = new Vue({
      el: '#global-nav',

      data()
      {
        return {
          storage: {
            categories: [],
            groups: []
          },
          is_booted: false
        }
      },

      mounted: function()
      {
        this.storage.categories = _.categories.storage;
        this.storage.groups = _.groups.storage;
      },

      methods: {
        isBooted: function()
        {
          return this.is_booted;
        },
        clickCategory: function( index )
        {
          this.current = this.storage.categories[index];

          _.shop.update( 'category', this.current );
        },
        clickGroup: function( index )
        {
          _.shop.update( 'group', this.storage.groups[index] );
        }
      }
    });
  }

  registerGlobalNavToggleEvent()
  {
    const toggle_button = document.querySelector('.header--nav-item button');
    const backdrop = document.querySelector('.nav--backdrop');

    toggle_button.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
      event.preventDefault();

      if( app.element.classList.contains('nav--is-active')){
        app.element.classList.remove('nav--is-active');
        this.fixContentArea(false);
      }else{
        app.element.classList.add('nav--is-active');
        this.fixContentArea(true);
      }
    });

    backdrop.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
      event.preventDefault();

      app.element.classList.remove('nav--is-active');
      this.fixContentArea(false);
    });
  }

  fixContentArea( flag )
  {

  }

  cleanCategoryData( items )
  {
    items.forEach(( item, index ) => {
      items[index].url = app.shopUrl() +'?mode=cate&cbid='+ item.id_big +'&csid=0';

      if(item.children.length){
        item.children.forEach(( child, _index ) => {
          items[index].children[_index].url = app.shopUrl() +'?mode=cate&cbid='+ item.id_big +'&csid='+ child.id_small;
        });
      }
    });

    return items;
  }

  cleanGroupData( items )
  {
    items.forEach(( item, index ) => {
      items[index].url = app.shopUrl() +'?mode=grp&gid='+ item.id;
    });

    return items;
  }

  cleanProductData( items )
  {
    // 作成日でソート
    items.sort(
      ( x, y ) => x.sort - y.sort
    );

    items.forEach(( item, index ) => {
      items[index].url = app.shopUrl() +'?pid='+ item.id;

      let now = ( new Date()).getTime();
      let ref = item.make_date * 1000;

      if(( now - ref ) < (( app.secondsOfDay() * 1000 ) * 7 )){
        items[index].is_new = true;
      }else{
        items[index].is_new = false;
      }

      let date = new Date();
      date.setTime(ref);
      items[index].make_date = date;

      items[index].sales_price_including_tax_formatted = numberFormat(item.sales_price_including_tax);
    });

    return items;
  }
}