import { UserAgent } from './ua.js';

export class Config
{
  constructor()
  {
    this.init();
  }

  init()
  {
    this.repository = {
      runMode: /^localhost\./.test(location.host) ? 'development' : 'production',
      location: {
        hash: location.hash,
        host: location.host,
        href: location.href,
        origin: location.origin,
        pathname: location.pathname,
        port: location.port,
        protocol: location.protocol,
        search: location.search
      },
      ua: new UserAgent()
    };

    // let readonly;
    // properties.forEach(( property ) => {
    //   if(readonly){

    //   }else{
    //     Object.defineProperty( this, property, {
    //       get: () => {console.log(property);
    //         let repository = this.repository;
    //         let prop, props = property.split('.');

    //         let i, len;
    //         for( i = 0, len = props.length - 1; i < len; i++ ){
    //           prop = props[i];console.log(prop);
    //           let candidate = repository[prop];
    //           if( candidate !== undefined ){
    //             repository = candidate;
    //           }else{
    //             break;
    //           }
    //         }

    //         return repository[prop[i]];
    //       }
    //     });
    //   }
    // });
  }

  // get( property )
  // {
  //   let repository = this.repository;
  //   let prop, props = property.split('.');
  //   let i, len;

  //   for( i = 0, len = props.length - 1; i < len; i++ ){
  //     prop = props[i];

  //     let candidate = repository[prop];
  //     if( candidate !== undefined ){
  //       repository = candidate;
  //     }else{
  //       break;
  //     }
  //   }

  //   return repository[props[i]];
  // }
  get( property )
  {
    const _ = function( repository, property )
    {
      if(!property){
        return repository;
      }

      let properties = property.split('.');

      return _( repository[ properties.shift()], properties.join('.'));
    };

    return _( this.repository, property );
  }

  set( property, value )
  {
    const _ = ( repository, property ) => {
      if(!property){
        return repository;
      }

      let properties = property.split('.');

      let key = properties.shift();
      let candidate = repository[key];
      if( candidate !== undefined ){
        return _( candidate, properties.join('.'));
      }else{
        repository[key] = {};
        return _( repository[key], properties.join('.'))
      }
    };

    let properties = property.split('.');
    let key = properties.pop();

    let repository = _( this.repository, properties.join('.'));

    repository[key] = value;
  }

  dump()
  {
    console.log(this.repository);
  }
}