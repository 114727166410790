const API_URL = 'https://unpleasant-pops.com/api/index.php';

const Promise = require('es6-promise').Promise;


export class Request
{
  static get( parameters, callback )
  {
    parameters = this.setCorrectParameters(parameters);

    if( !parameters.hasOwnProperty('method')){
      parameters.method = 'GET';
    }

    if( !parameters.hasOwnProperty('mode')){
      parameters.mode = 'no-cors';
    }

    let api = parameters.hasOwnProperty('api') ? parameters.api : API_URL;
    delete(parameters.api);

    let query = [];
    Object.keys(parameters).forEach(( key ) => {
      query.push( key +'='+ parameters[key] );
    });

    api += '?'+ query.join('&');

    if( /localhost/.test(location.href)){
    // @begin dummy

    if( parameters.scope == 'products' ){
      api = '/assets/dummy/products.json';
    }else if( parameters.scope == 'categories' ){
      api = '/assets/dummy/categories.json';
    }else if( parameters.scope == 'groups' ){
      api = '/assets/dummy/groups.json';
    }

    // @end dummy
    }
//console.log('api:'+api);
    fetch( api, parameters )
      .then(( response ) => {//console.log(response);
        if(!response.ok){
          // throw new Error(`Fetch: ${response.status} ${response.statusText}`);
          // @todo statusはレスポンスから貰うようにする
          // callback({
          //   status: 400,
          //   response: response
          // });
//          console.log('bad1');
        }
//console.log('ok1');
        return response.json();
      })
      .then(( response ) => {//console.log(response);
        //console.log('ok2');
        callback({
          status: 200,
          data: response
        });
      })
      .catch(( error ) => {//console.log('bad2');console.log('error:'+error);
        //console.error(`[FetchAPI] ${error}, ${API_URL}`);
        // @todo statusはレスポンスから貰うようにする
        // callback({
        //   status: 400,
        //   response: ''
        // });
      });
  }

  static post( parameters, callback )
  {
    parameters = this.setCorrectParameters(parameters);

    if( !parameters.hasOwnProperty('method')){
      parameters.method = 'POST';
    }

    if( !parameters.hasOwnProperty('mode')){
      parameters.mode = 'no-cors';
    }

    if( !parameters.hasOwnProperty('headers')){
      parameters.headers = {
        'Accept':       'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      };
    }

    if( !parameters.hasOwnProperty('body')){
      if( parameters.hasOwnProperty('data')){
        let fd = new FormData();
        fd.append( 'scope', parameters.data.scope );

        parameters.body = fd;

        delete parameters.data;
      }
    }

    const api = parameters.hasOwnProperty('api') ? parameters.api : API_URL;

    fetch( api, parameters )
      .then(( response ) => {
        if(!response.ok){
          // throw new Error(`Fetch: ${response.status} ${response.statusText}`);
          // @todo statusはレスポンスから貰うようにする
          // callback({
          //   status: 400,
          //   response: response
          // });

          return;
        }

        return response.json();
      })
      .then(( response ) => {
        callback({
          status: 200,
          data: response
        });
      })
      .catch(( error ) => {
        //console.log(JSON.stringify(error));
        //console.error(`[FetchAPI] ${error}, ${API_URL}`);
        // @todo statusはレスポンスから貰うようにする
        // callback({
        //   status: 400,
        //   response: error
        // });
      });
  }

  static call( parameters, callback )
  {
  }

  static setCorrectParameters( parameters )
  {
    return parameters;
  }
}