//import Vue from 'vue';

export class Controller
{
  /**
   ** @param  HTMLElement element
   **/
  constructor( element )
  {
    this.element   = element;

    this.is_booted = false;
  }

  isBooted()
  {
    return this.is_booted;
  }
}