import { Controller } from '../foundation/controller';
import { Request } from '../foundation/request';

import Vue from 'vue';

export class Top extends Controller
{
  constructor( element )
  {
    super(element);
  }

  boot()
  {
    const _ = this;

    this.shop = new Vue({
      el: '#shop',

      data()
      {
        return {
          storage: {
            products: [],
            categories: [],
            groups: []
          },
          is_booted: false,
          is_loading: false
        }
      },

      mounted: function()
      {
        this.load({
          mode: '',
          scope: 'products',
          limit: 12
        });
      },

      methods: {
        load: function( parameters )
        {
          this.is_loading = true;

          Request.get(
            {
              scope: 'categories'
            },
            ( response ) => {
              this.storage.categories = _.cleanShopCategoryData( response.data.categories.filter(( item ) => {
                return item.display_state == 'showing';
              }));

              Request.get(
                {
                  scope: 'groups'
                },
                ( response ) => {
                  this.storage.groups = _.cleanShopGroupData( response.data.groups.filter(( item ) => {
                    return item.display_state == 'showing';
                  }));

                  const mode = parameters.mode;
                  delete parameters.mode;

                  const name = parameters.name;
                  delete parameters.name;

                  Request.get( parameters, ( response ) => {
                    this.storage.products = _.cleanShopProductData( response.data.products.filter(( item ) => {
                      return item.display_state == 'showing';
                    }), this.storage.categories );

                    this.is_loading = false;

                    if(!this.is_booted){
                      this.is_booted = true;

                      _.booted();
                    }
                  });
                }
              );

            }
          );
        },
        isBooted: function()
        {
          return this.is_booted;
        }
      }
    });

    this.news = new Vue({
      el: '#news',

      data()
      {
        return {
          storage: {
            articles: [],
            categories: [],
            monthly: []
          },
          is_booted: false,
          is_loading: false
        }
      },

      mounted: function()
      {
        this.load({
          // mode: '',
          api: 'https://unpleasant-pops.com/api/data-api.php',
          __mode: 'entries',
          limit: 12
        });
      },

      methods: {
        load: function( parameters )
        {
          this.is_loading = true;

          Request.get(
            {
              api: 'https://unpleasant-pops.com/api/categories'
            },
            ( response ) => {
              this.storage.categories = response.data.results;

              Request.get(
                {
                  api: 'https://unpleasant-pops.com/api/archives',
                },
                ( response ) => {
                  this.storage.monthly = response.data.results;

                  Request.get(
                    {
                      api: 'https://unpleasant-pops.com/api/articles',
                    },
                    ( response ) => {
                      this.storage.articles = response.data.results;

                      this.is_loading = false;
                      if(!this.is_booted){
                        this.is_booted = true;

                        _.booted();
                      }
                    }
                  );
                }
              );
            }
          );
        },
        isBooted: function()
        {
          return this.is_booted;
        }
      }
    });
  }

  booted()
  {
    if(  this.shop.isBooted()
      && this.news.isBooted()
    ){
      this.is_booted = true;

      this.tidyUpLayout();
      this.registerGlobalNavToggleEvent();

      app.booted();
    }
  }

  tidyUpLayout()
  {
    const width = window.innerWidth;

    [
      '.archives.top-shop--categories .archives--content',
      '.archives.top-shop--groups .archives--content',
      '.archives.top-news--categories .archives--content',
      '.archives.top-news--monthly .archives--content'
    ].forEach(( name ) => {
      let block = this.element.querySelector(name);
      let length = block.querySelectorAll('.archives--content-item').length;
      let flag = false;

      if( width < 768 ){
        if( length < 2 ){
          flag = true;
        }
      }else if( width < 1280 ){
        if( length < 3 ){
          flag = true;
        }
      }else{
        if( length < 4 ){
          flag = true;
        }
      }

      if(flag){
        block.querySelector('ul').style.justifyContent = 'center';
      }
    });
  }

  registerGlobalNavToggleEvent()
  {
    const toggle_button = document.querySelector('.header--nav-item button');
    const backdrop = document.querySelector('.app--nav .nav--backdrop');

    toggle_button.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
      event.preventDefault();

      if( app.element.classList.contains('nav--is-active')){
        app.element.classList.remove('nav--is-active');
        this.fixContentArea(false);
      }else{
        app.element.classList.add('nav--is-active');
        this.fixContentArea(true);
      }
    });

    backdrop.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
      event.preventDefault();

      app.element.classList.remove('nav--is-active');
      this.fixContentArea(false);
    });
  }

  fixContentArea( flag )
  {

  }

  cleanShopProductData( items, categories )
  {
    // 作成日でソート
    items.sort(
      ( x, y ) => x.sort - y.sort
    );

    items.forEach(( item, index ) => {
      items[index].url = app.shopUrl() +'?pid='+ item.id;

      let now = ( new Date()).getTime();
      let ref = item.make_date * 1000;

      if(( now - ref ) < (( app.secondsOfDay() * 1000 ) * 7 )){
        items[index].is_new = true;
      }else{
        items[index].is_new = false;
      }

      let date = new Date();
      date.setTime(ref);
      items[index].make_date = date;

      items[index].sales_price_including_tax_formatted = numberFormat(item.sales_price_including_tax);

      let category = categories.filter(( _category ) => {
        return _category.id_big == item.category.id_big;
      });

      items[index].category.name = category[0].name;
    });

    return items;
  }

  cleanShopCategoryData( items )
  {
    items.forEach(( item, index ) => {
      items[index].url = app.shopUrl() +'?mode=cate&cbid='+ item.id_big +'&csid=0';

      if(item.children.length){
        item.children.forEach(( child, _index ) => {
          items[index].children[_index].url = app.shopUrl() +'?mode=cate&cbid='+ item.id_big +'&csid='+ child.id_small;
        });
      }
    });

    return items;
  }

  cleanShopGroupData( items )
  {
    items.forEach(( item, index ) => {
      items[index].url = app.shopUrl() +'?mode=grp&gid='+ item.id;
    });

    return items;
  }

  cleanNewsArticleData( items )
  {
    items.forEach(( item, index ) => {
      let now = ( new Date()).getTime();
      let ref = ( new Date( item.date +' '+ item.time )).getTime();

      if(( now - ref ) < (( app.secondsOfDay() * 1000 ) * 7 )){
        items[index].is_new = true;
      }else{
        items[index].is_new = false;
      }
    });

    return items;
  }
}