import '../scss/index.scss';

require('./foundation/polyfill');
require('./foundation/extends');
require('./foundation/helper');

import { App } from './foundation/app';

window.app = new App();

require('./foundation/app/boot');
console.log(app);