import { Controller } from '../foundation/controller';
import { Request } from '../foundation/request';

import Vue from 'vue';

export class News extends Controller
{
  constructor( element )
  {
    super(element);
  }

  boot()
  {
    let pathname = config('location.pathname')
                     .replace( '/news/', '' )
                     .replace( /\/$/, '' );

    let search = [...new URLSearchParams( config('location.search')).entries()].reduce(
      function( obj, entry )
      {
        return { ...obj, [entry[0]]: entry[1] }
      }, {}
    );

    if( pathname != '' ){
      if( /^([0-9]+)\-([0-9]+)\/?$/.test(pathname)){
        // 月別アーカイブ
        search.__mode = 'monthly';
        search.date = pathname;
      }else if( /^([0-9a-zA-Z\-\_]+)\/?$/.test(pathname)){
        // カテゴリアーカイブ
        search.__mode = 'categories';
        search.dirname = pathname;
      }else if( /^tag\:/.test(pathname)){
        // タグ検索
        pathname = pathname.split(':');

        search.__mode = 'tag';
        search.tag = pathname[1];
      }else if( /^search\:/.test(pathname)){
        // 検索
        pathname = pathname.split(':');

        search.__mode = 'search';
        search.word = pathname[1];
      }else{
        // 記事
        pathname = pathname.split(':');

        search.__mode = 'individual';
        search.date = pathname[0];
        search.basename = pathname[1];
      }

      this.load(search);
    }else{
      this.load({
        __mode: 'index'
      });
    }
  }

  load( parameters )
  {
    const _ = this;

    _.news = new Vue({
      el: '#news',

      data()
      {
        return {
          storage: [],
          is_booted: false,
          is_loading: false
        }
      },

      mounted: function()
      {
        this.load();
      },

      methods: {
        load: function()
        {
          this.is_loading = true;

          parameters.api = 'https://unpleasant-pops.com/api/articles';
          if( parameters.__mode != 'individual' ){
            parameters.limit = 24;
          }

          Request.get( parameters, ( response ) => {
            if( parameters.__mode != 'individual' ){
              this.storage = _.cleanNewsData(response.data.results);
            }//else{console.log('article');
            //  this.storage = {};
            //  this.storage.article = response.data.results[0];
            //  this.storage.pager = response.data.pager;
            //}

            this.is_loading = false;
            if(!this.is_booted){
              this.is_booted = true;

              _.booted();
            }
          });
        },
        isBooted: function()
        {
          return this.is_booted;
        }
      }
    });

    _.categories = new Vue({
      el: '#categories',

      data()
      {
        return {
          storage: [],
          is_booted: false,
          is_loading: false
        }
      },

      mounted: function()
      {
        this.load({});
      },

      methods: {
        load: function( parameters )
        {
          this.is_loading = true;

          Request.get(
            {
              api: 'https://unpleasant-pops.com/api/categories',
              limit: 24
            },
            ( response ) => {
              this.storage = response.data.results;

              this.is_loading = false;
              if(!this.is_booted){
                this.is_booted = true;

                _.booted();
              }
            }
          );
        },
        isBooted: function()
        {
          return this.is_booted;
        }
      }
    });

    _.monthly = new Vue({
      el: '#monthly',

      data()
      {
        return {
          storage: [],
          is_booted: false,
          is_loading: false
        }
      },

      mounted: function()
      {
        this.load({});
      },

      methods: {
        load: function( parameters )
        {
          this.is_loading = true;

          Request.get(
            {
              api: 'https://unpleasant-pops.com/api/archives',
              limit: 24
            },
            ( response ) => {
              this.storage = response.data.results;

              this.is_loading = false;
              if(!this.is_booted){
                this.is_booted = true;

                _.booted();
              }
            }
          );
        },
        isBooted: function()
        {
          return this.is_booted;
        }
      }
    });
  }

  cleanNewsData( data )
  {
    data.forEach(( item, index, original ) =>{
      let ref = new Date(item.published_on);
      let now = new Date();

      if(( now.getTime() - ref.getTime()) < 604800 ){
        return original[index].is_new = true;
      }else{
        return original[index].is_new = false;
      }
    });

    return data;
  }

  checkDateDiff( item )
  {
    let ref = new Date(item.published_on);
  }

  booted()
  {
    const _ = this;

    if(  _.news.isBooted()
      && _.categories.isBooted()
      && _.monthly.isBooted()
    ){
      _.is_booted = true;

      _.categories_in_nav = new Vue({
        el: '#categories-in-nav',

        data()
        {
          return {
            storage: []
          }
        },

        mounted: function()
        {
          this.storage = _.categories.storage;
        }
      });

      _.monthly_in_nav = new Vue({
        el: '#monthly-in-nav',

        data()
        {
          return {
            storage: []
          }
        },

        mounted: function()
        {
          this.storage = _.monthly.storage;
        }
      });

      _.categories_in_footer = new Vue({
        el: '#categories-in-footer',

        data()
        {
          return {
            storage: []
          }
        },

        mounted: function()
        {
          this.storage = _.categories.storage;
        }
      });

      _.monthly_in_footer = new Vue({
        el: '#monthly-in-footer',

        data()
        {
          return {
            storage: []
          }
        },

        mounted: function()
        {
          this.storage = _.monthly.storage;
        }
      });

      _.registerShareButtonEvent();
      _.registerGlobalNavToggleEvent();
      _.registerSearchFormEvent();

      _.tidyUpLayout();

      app.booted();
    }
  }

  tidyUpLayout()
  {
    const width = window.innerWidth;

    [
      '.archives.categories .archives--content',
      '.archives.monthly .archives--content'
    ].forEach(( name ) => {
      let block = this.element.querySelector(name);
      let length = block.querySelectorAll('.archives--content-item').length;
      let flag = false;

      if( width < 768 ){
        if( length < 3 ){
          flag = true;
        }
      }else if( width < 1024 ){
        if( length < 4 ){
          flag = true;
        }
      }else{
        if( length < 5 ){
          flag = true;
        }
      }

      if(flag){
        block.querySelector('ul').style.justifyContent = 'center';
      }
    });
  }

  registerSearchFormEvent()
  {
    const tool = document.querySelector('.news--content-tool--search');
    const input = tool.querySelector('input[name="keyword"]');
    const button = tool.querySelector('button');

    button.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
      event.preventDefault();

      location.href = 'https://unpleasant-pops.com/news/search:'+ input.value;
    });
  }

  registerShareButtonEvent()
  {
    const block = this.element.querySelector('.news--share');
    if( block != null ){
      const buttons = block.querySelectorAll('button[data-vendor]').toArray();
      buttons.forEach(( button ) => {
        button.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
          event.preventDefault();

          let api;
          let vendor = button.dataset.vendor;
          switch(vendor){
            case 'line':
              api = 'http://line.me/R/msg/text/?('+ document.title +' '+ location.href +')';
              break;
            case 'facebook':
              api = 'https://www.facebook.com/sharer/sharer.php?u='+ location.href +'&t=('+ document.title +')';
              break;
            case 'twitter':
              api = 'https://twitter.com/share?shareUrl='+ location.href +'&text=('+ document.title +')';
              break;
          }

          window.open( api, '_blank', 'width=600,height=600,menubar=no,toolbar=no,scrollbars=yes' );
        });
      });
    }
  }

  registerGlobalNavToggleEvent()
  {
    const toggle_button = document.querySelector('.header--nav-item button');
    const backdrop = document.querySelector('.nav--backdrop');

    toggle_button.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
      event.preventDefault();

      if( app.element.classList.contains('nav--is-active')){
        app.element.classList.remove('nav--is-active');
        this.fixContentArea(false);
      }else{
        app.element.classList.add('nav--is-active');
        this.fixContentArea(true);
      }
    });

    backdrop.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
      event.preventDefault();

      app.element.classList.remove('nav--is-active');
      this.fixContentArea(false);
    });
  }

  fixContentArea( flag )
  {

  }
}