import Vue from 'vue';

import { Request } from './request';
import { Intersect } from './observer/intersect';

import { Top } from '../controllers/top';
import { Shop } from '../controllers/shop';
import { News } from '../controllers/news';

const SHOP_URL = 'https://unpleasant-pops.shop-pro.jp/';
const SECONDS_OF_DAY = 86400;

export class App
{
  constructor()
  {
    this.id = ( document.getElementById('app') != null )
              ? 'app' // グローバルトップ
              : ( document.getElementById('us--app'))
                ? 'us--app' // ショップコンテンツ
                : ( document.getElementById('un--app'))
                  ? 'un--app' // ニュースコンテンツ
                  : '';

    this.element = document.getElementById(this.id);

    this.init();
  }

  shopUrl()
  {
    return SHOP_URL;
  }

  secondsOfDay()
  {
    return SECONDS_OF_DAY;
  }

  init()
  {
    this.name = '【Unpleasant POPS!】UP Online.';
    this.title = this.element.querySelector('.app--title');

    switch(this.id){
      case 'app':
        this.controller = new Top(this.element);
        break;
      case 'us--app':
        this.controller = new Shop(this.element);
        break;
      case 'un--app':
        this.controller = new News(this.element);
        break;
    }
  }

  /**
   ** @param  function  callback
   **/
  boot( callback )
  {
    this.controller.boot();

    if(callback){
      callback();
    }
  }

  booted()
  {
    this.element.setAttribute( 'data-appid', '.0' );

    this.addAppId( this.element, this.element.dataset.appid );
    this.updateClassList();

    Intersect.observe(
      this.element.querySelectorAll('.app--header').toArray(),
      ( element, flag, observer ) => {
        if( element.classList.contains('app--header')){
          if(flag){
            this.element.classList.remove('icon--is-positive');
          }else{
            this.element.classList.add('icon--is-positive');
          }
        }
      }
    );

    // Intersect.observe(
    //   this.element.querySelectorAll('.top-news--header').toArray(),
    //   ( element, flag, observer ) => {
    //     if( element.classList.contains('top-news--header')){
    //       if(flag){
    //         console.log(document.getElementById('news').getBoundingClientRect().top);
    //         this.element.classList.remove('icon--is-positive');
    //       }else{
    //         console.log(document.getElementById('news').getBoundingClientRect().top);
    //         this.element.classList.add('icon--is-positive');
    //       }
    //     }
    //   },
    //   {
    //     rootMargin: "100vh 0px 0px 0px"
    //   }
    // );

    // Intersect.observe(
    //   this.element.querySelectorAll('[data-observe]').toArray(),
    //   ( element, flag, observer ) => {
    //     // let timeout = Math.random() * 250;

    //     if(flag){
    //     //   setTimeout(() => {
    //        element.dataset.observe = false;
    //     //   }, timeout );
    //     }else{
    //     //   setTimeout(() => {
    //        element.dataset.observe = true;
    //     //   }, timeout );
    //     }
    //   }
    // );
  }

  update( parameters )
  {
    document.title = parameters.title +' - '+ this.name;

    this.title.textContent = parameters.title;
    this.title.previousElementSibling.classList.remove('is--hidden');
  }

  /**
   ** 指定されたHTMLElement配下のHTMLElementにAPP_IDを付加する。
   **
   ** @param  HTMLElement  parent
   ** @param  String       id
   **
   ** @return void
   **/
  addAppId( parent, id )
  {
    // 除外するタグ
    const tags = [
      'abbr', 'acronym',
      'b', 'bdi', 'bdo', 'big', 'br',
      'center', 'circle', 'cite', 'code', 
      'del', 'dfn',
      'ellipse', 'em',
      'font',
      'hr',
      'i', 'ins',
      'kbd',
      'line',
      'mark',
      'noframes', 'noscript',
      'path', 'polygon', 'polyline',
      'q',
      'rect',
      's', 'samp', 'small', 'span', 'strike', 'strong', 'sub', 'sup',
      'tt',
      'u',
      'var' 
    ];

    const _ = ( _element, _id, _idx ) => {
      _element.setAttribute( 'data-appid', _id + '.'+ _idx );

      this.addAppId( _element, _id +'.'+ _idx );
    };

    if( parent.hasChildNodes()){
      // Elementノードのみ
      parent.children.toArray().forEach(( element, idx ) => {
        if( config('ua.os.WINDOWS') && config('ua.browser.IE')){

        }else{
          if( !tags.includes( element.tagName.toLowerCase())){
            _( element, id, idx );
          }else{
            let style = window.getComputedStyle(element);

            if( style.getPropertyValue('display') == 'block' ){
              _( element, id, idx );
            }

            // @todo 親要素の`style.display`の値が`flex`の場合
          }
        }
      });
    }
  }

  /**
   ** ルート要素にクラスを付加する
   **
   ** @return void
   **/
  updateClassList()
  {
    setTimeout(() => {
      let class_list = [
        ( config('ua.os.IOS') ? 'ios'
          : config('ua.os.MAC') ? 'mac'
          : config('ua.os.WINDOWS') ? 'windows'
          : config('ua.os.LINUX') ? 'linux'
          : ''
        ),
        ( config('ua.platform.MOBILE') ? 'mobile'
          : config('ua.platform.TABLET') ? 'tablet'
          : config('ua.platform.DESKTOP') ? 'desktop'
          : ''
        ),
        ( config('ua.browser.IE') ? 'ie'
          : config('ua.browser.EDGE') ? 'edge'
          : config('ua.browser.CHROME') ? 'chrome'
          : config('ua.browser.FIREFOX') ? 'firefox'
          : config('ua.browser.SAFARI') ? 'safari'
          : config('ua.browser.OPERA') ? 'opera'
          : ''
        ),
        'is--mounted'
      ];

      class_list = class_list.filter(( item ) => {
        return item != '';
      });

      this.element.classList.add(...class_list);
      this.element.classList.remove('is--loading');
    }, 10 );
  }
}