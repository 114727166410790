/**
 ** windowオブジェクトを拡張
 **/
if(!window.trigger){
  window.trigger = function( node, event )
  {
    if(document.createEvent){
      // IE以外
      const e = document.createEvent('HTMLEvents');
      e.initEvent( event, true, true );

      return node.dispatchEvent(e);
    }else{
      // IE
      const e = document.createEventObject();

      return node.fireEvent( 'on'+ event, e );
    }
  }
}

/**
 ** HTMLCollectionオブジェクトを拡張
 **/
if(!HTMLCollection.prototype.toArray){
  HTMLCollection.prototype.toArray = function()
  {
    return Array.prototype.slice.call( this, 0 );
  };
}

/**
 ** NodeListオブジェクトを拡張
 **/
if(!NodeList.prototype.toArray){
  NodeList.prototype.toArray = function()
  {
    return Array.prototype.slice.call( this, 0 );
  };
}

/**
 ** HTMLElementオブジェクトを拡張
 **/
if(!HTMLElement.prototype.trigger){
  HTMLElement.prototype.trigger = function( event )
  {
    if(document.createEvent){
      // IE以外
      const e = document.createEvent('HTMLEvents');
      e.initEvent( event, true, true );

      return this.dispatchEvent(e);
    }else{
      // IE
      const e = document.createEventObject();

      return this.fireEvent( 'on'+ event, e );
    }
  }
}

/**
 ** Arrayオブジェクトを拡張
 **/
if(!Array.prototype.chunk){
  Array.prototype.chunk = function( size )
  {
    return this.reduce(
      ( accumulator, current, idx ) => (( idx % size )
        ? accumulator
        : [ ...accumulator, this.slice( idx, ( idx + size ))]),
      []
    );
  }
}

if(!Array.prototype.copy){
  Array.prototype.copy = function()
  {
    return this.concat();
  }
}

if(!Array.prototype.pluck){
  Array.prototype.pluck = function( key )
  {
    return this.map(( item ) => {
      return item[key];
    });
  }
}

/**
 ** Stringオブジェクトを拡張
 **/
if(!String.prototype.toCamelCase){
  String.prototype.toCamelCase = function()
  {
    return this.replace( /(?:^\w|[A-Z]|\b\w)/g, function( char, index )
    {
      return ( index === 0 ) ? char.toLowerCase() : char.toUpperCase();
  }).replace( /\s+/g, '' );
  }
}